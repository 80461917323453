import axios from 'axios';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
import { IUser } from '@/shared/model/user.model';

export default class UserManagementService {
  public get(email: string): Promise<any> {
    return axios.get(`/api/admin/users/${email}`);
  }

  public create(user: IUser): Promise<any> {
    return axios.post('/api/admin/users', user);
  }

  public update(user: IUser): Promise<any> {
    return axios.put('/api/admin/users', user);
  }

  public retrieve(req?: any): Promise<any> {
    return axios.get(
      `/api/admin/users` + '?searchType=' + req.params.searchType + `&${buildPaginationQueryOpts(req.params.paginationQuery)}`
    );
  }

  public retrieveAuthorities(): Promise<any> {
    return axios.get('/api/authorities');
  }

  public removeUserAppId(userId: number): Promise<any> {
    return axios.put(`/api/admin/user/app/${userId}`);
  }
}
