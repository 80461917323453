import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');
const CorsiBlock = () => import('@/entities/corsi-block/corsi-block.vue');
const CorsiBlockDetails = () => import('@/entities/corsi-block/corsi-block-details.vue');
const GoNoGo = () => import('@/entities/goNoGo/goNoGo.vue');
const GoNoGoDetails = () => import('@/entities/goNoGo/goNoGo-details.vue');
const NBack = () => import('@/entities/n-back/n-back.vue');
const NBackDetails = () => import('@/entities/n-back/n-back-details.vue');
const Tmt = () => import('@/entities/tmt/tmt.vue');
const TmtDetails = () => import('@/entities/tmt/tmt-details.vue');
// add-entity-to-router-import - import entities to the router here
const ComplianceCorsiBlock = () => import('@/entities/compliance/corsi-block.vue');
const ComplianceTmt = () => import('@/entities/compliance/tmt.vue');
const ComplianceGoNoGo = () => import('@/entities/compliance/goNoGo.vue');
const ComplianceNBack = () => import('@/entities/compliance/nBack.vue');
const Survey = () => import('@/entities/survey/survey.vue');
const Question = () => import('@/entities/survey/question.vue');
const QuestionNew = () => import('@/entities/survey/question-edit.vue');
const CorsiTest = () => import('@/entities/corsi-test/corsi-test.vue');
const CorsiTestView = () => import('@/entities/corsi-test/corsi-test-details.vue');
const CorsiComplianceDetailView = () => import('@/entities/compliance/corsi-block-detail.vue');
const GoNoGoComplianceDetailView = () => import('@/entities/compliance/goNoGo-detail.vue');
const NBackComplianceDetailView = () => import('@/entities/compliance/n-back-detail.vue');
const TmtComplianceDetailView = () => import('@/entities/compliance/tmt-detail.vue');

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'corsi-block',
      name: 'corsiBlock',
      component: CorsiBlock,
      // meta: { authorities: [Authority.ADMIN, Authority.CRC, Authority.CRO] }
    },
    {
      path: 'corsi-block/:corsiBlockId/view',
      name: 'corsiBlockView',
      component: CorsiBlockDetails,
      meta: { authorities: [Authority.ADMIN, Authority.CRC, Authority.CRO] },
    },
    // add-entity-to-router -  add entities to the router here
    {
      path: 'goNoGo',
      name: 'goNoGo',
      component: GoNoGo,
      meta: { authorities: [Authority.ADMIN, Authority.CRC, Authority.CRO] },
    },
    {
      path: 'goNoGo/:goNoGoModelId/view',
      name: 'goNoGoView',
      component: GoNoGoDetails,
      meta: { authorities: [Authority.ADMIN, Authority.CRC, Authority.CRO] },
    },
    {
      path: 'n-back',
      name: 'nBack',
      component: NBack,
      meta: { authorities: [Authority.ADMIN, Authority.CRC, Authority.CRO] },
    },
    {
      path: 'n-back/:nBackId/view',
      name: 'nBackView',
      component: NBackDetails,
      meta: { authorities: [Authority.ADMIN, Authority.CRC, Authority.CRO] },
    },
    {
      path: 'tmt',
      name: 'tmt',
      component: Tmt,
      meta: { authorities: [Authority.ADMIN, Authority.CRC, Authority.CRO] },
    },
    {
      path: 'tmt/:tmtId/view',
      name: 'tmtView',
      component: TmtDetails,
      meta: { authorities: [Authority.ADMIN, Authority.CRC, Authority.CRO] },
    },
    {
      path: 'compliance/corsi',
      name: 'complianceCorsi',
      component: ComplianceCorsiBlock,
      meta: { authorities: [Authority.ADMIN, Authority.CRC, Authority.CRO] },
    },
    {
      path: 'compliance/tmt',
      name: 'complianceTmt',
      component: ComplianceTmt,
      meta: { authorities: [Authority.ADMIN, Authority.CRC, Authority.CRO] },
    },
    {
      path: 'compliance/goNoGo',
      name: 'complianceGoNoGo',
      component: ComplianceGoNoGo,
      meta: { authorities: [Authority.ADMIN, Authority.CRC, Authority.CRO] },
    },
    {
      path: 'compliance/n-back',
      name: 'complianceNBack',
      component: ComplianceNBack,
      meta: { authorities: [Authority.ADMIN, Authority.CRC, Authority.CRO] },
    },
    {
      path: 'survey/question',
      name: 'question',
      component: Question,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'survey/answer',
      name: 'Survey',
      component: Survey,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'survey/question-edit',
      name: 'QuestionNew',
      component: QuestionNew,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'corsi-test/corsi',
      name: 'CorsiTest',
      component: CorsiTest,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'corsi-test/:corsiBlockId/view',
      name: 'CorsiTestView',
      component: CorsiTestView,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'corsi-compliance/:email/view',
      name: 'CorsiComplianceDetailView',
      component: CorsiComplianceDetailView,
      meta: { authorities: [Authority.ADMIN, Authority.CRC, Authority.CRO] },
    },
    {
      path: 'goNoGo-compliance/:email/view',
      name: 'GoNoGoComplianceDetailView',
      component: GoNoGoComplianceDetailView,
      meta: { authorities: [Authority.ADMIN, Authority.CRC, Authority.CRO] },
    },
    {
      path: 'n-back-compliance/:email/view',
      name: 'NBackComplianceDetailView',
      component: NBackComplianceDetailView,
      meta: { authorities: [Authority.ADMIN, Authority.CRC, Authority.CRO] },
    },
    {
      path: 'tmt-compliance/:email/view',
      name: 'TmtComplianceDetailView',
      component: TmtComplianceDetailView,
      meta: { authorities: [Authority.ADMIN, Authority.CRC, Authority.CRO] },
    },
  ],
};
