var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-dropdown-item",
        { attrs: { to: "/corsi-test/corsi", "active-class": "active" } },
        [
          _c(
            "span",
            [
              _c("font-awesome-icon", {
                attrs: { icon: "fa-solid fa-staris" },
              }),
              _vm._v("Corsi-blocks"),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }