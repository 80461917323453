import { Component, Inject, Vue } from 'vue-property-decorator';
import LoginService from '@/account/login.service';
import AccountService from '@/account/account.service';

import EntitiesMenu from '@/entities/entities-menu.vue';
import EntitiesCompainceMenu from '@/entities/entities-compliance-menu.vue';
import EntitiesSurveyMenu from '@/entities/entities-survey-menu.vue';
import EntitiesCorsiTestMenu from '@/entities/entities-corsi-test-menu.vue';

@Component({
  components: {
    'entities-menu': EntitiesMenu,
    'entities-compliance-menu': EntitiesCompainceMenu,
    'entities-survey-menu': EntitiesSurveyMenu,
    'entities-corsi-test-menu': EntitiesCorsiTestMenu,
  },
})
export default class JhiNavbar extends Vue {
  @Inject('loginService')
  private loginService: () => LoginService;

  @Inject('accountService') private accountService: () => AccountService;
  public version = 'v' + VERSION;
  private currentLanguage = this.$store.getters.currentLanguage;
  private languages: any = this.$store.getters.languages;
  private hasAnyAuthorityValues = {};
  private hasAdminAuthorityValue = false;
  private hasUserAuthorityValue = false;

  created() {}

  public subIsActive(input) {
    const paths = Array.isArray(input) ? input : [input];
    return paths.some(path => {
      return this.$route.path.indexOf(path) === 0; // current path starts with this path string
    });
  }

  public logout(): Promise<any> {
    localStorage.removeItem('jhi-authenticationToken');
    sessionStorage.removeItem('jhi-authenticationToken');
    this.$store.commit('logout');
    if (this.$route.path !== '/') {
      return this.$router.push('/');
    }
    return Promise.resolve(this.$router.currentRoute);
  }

  public openLogin(): void {
    this.loginService().openLogin((<any>this).$root);
  }

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  public hasAnyAuthority(authorities: any): boolean {
    this.accountService()
      .hasAnyAuthorityAndCheckAuth(authorities)
      .then(value => {
        if (authorities === 'ROLE_ADMIN') {
          this.hasAdminAuthorityValue = value;
        } else if (authorities === 'ROLE_CRC') {
          this.hasUserAuthorityValue = value;
        }
      });
    if (authorities === 'ROLE_ADMIN') {
      return this.hasAdminAuthorityValue;
    } else if (authorities === 'ROLE_CRC') {
      return this.hasUserAuthorityValue;
    }
  }

  /**
  public hasAnyAuthority(authorities: any): boolean {
    this.accountService()
      .hasAnyAuthorityAndCheckAuth(authorities)
      .then(value => {
        console.log(value);
        return value;
      });
    return this.hasAnyAuthorityValues[authorities] ?? false;
  }
   */

  public get openAPIEnabled(): boolean {
    return this.$store.getters.activeProfiles.indexOf('api-docs') > -1;
  }

  public get inProduction(): boolean {
    return this.$store.getters.activeProfiles.indexOf('prod') > -1;
  }
}
