var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-dropdown-item",
        {
          staticClass: "custom-dropdown-item",
          attrs: { to: "/corsi-block", "active-class": "active" },
        },
        [
          _c(
            "span",
            [
              _c("font-awesome-icon", {
                attrs: { icon: "fa-solid fa-staris" },
              }),
              _vm._v("Corsi Blocks"),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("b-dropdown-item", { attrs: { to: "/goNoGo" } }, [
        _c("span", [_vm._v("GoNoGo")]),
      ]),
      _vm._v(" "),
      _c("b-dropdown-item", { attrs: { to: "/n-back" } }, [
        _c("span", [_vm._v("N-Back")]),
      ]),
      _vm._v(" "),
      _c("b-dropdown-item", { attrs: { to: "/tmt" } }, [
        _c("span", [_vm._v("TMT")]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }