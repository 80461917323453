var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-body" }, [
    _c("div", { staticClass: "row justify-content-center" }, [
      _c(
        "div",
        { staticClass: "col-md-8" },
        [
          _vm.authenticationError
            ? _c(
                "b-alert",
                {
                  attrs: {
                    show: "",
                    "data-cy": "loginError",
                    variant: "danger",
                  },
                },
                [
                  _c("strong", [_vm._v("로그인에 실패 하였습니다!")]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v("계정과 비밀번호를 다시 확인해주세요.\n      "),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-8" },
        [
          _c(
            "b-form",
            {
              attrs: { role: "form" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.doLogin()
                },
              },
            },
            [
              _c(
                "b-form-group",
                { attrs: { label: "계정", "label-for": "username" } },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "username",
                      type: "text",
                      name: "username",
                      autofocus: "",
                      placeholder: "계정을 입력해주세요.",
                      "data-cy": "username",
                    },
                    model: {
                      value: _vm.email,
                      callback: function ($$v) {
                        _vm.email = $$v
                      },
                      expression: "email",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                { attrs: { label: "비밀번호", "label-for": "password" } },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "password",
                      type: "password",
                      name: "password",
                      placeholder: "비밀번호를 입력해주세요.",
                      "data-cy": "password",
                    },
                    model: {
                      value: _vm.password,
                      callback: function ($$v) {
                        _vm.password = $$v
                      },
                      expression: "password",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "my-button",
                      attrs: { "data-cy": "submit", type: "submit" },
                    },
                    [_vm._v("접속")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("p"),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }