var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-dropdown-item",
        { attrs: { to: "/survey/question", "active-class": "active" } },
        [
          _c(
            "span",
            [
              _c("font-awesome-icon", {
                attrs: { icon: "fa-solid fa-staris" },
              }),
              _vm._v("설문 질문"),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("b-dropdown-item", { attrs: { to: "/survey/answer" } }, [
        _c("span", [_vm._v("설문 답변")]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }